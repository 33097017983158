import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App";
import "ant-design-vue/dist/reset.css";

// import PerfectScrollbar from "vue3-perfect-scrollbar";
// import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import { io } from "socket.io-client";

let ioUrl;

if (process.env.NODE_ENV === "development") {
  ioUrl = "http://localhost:8000";
} else {
  ioUrl = "https://api.supersearch.app/";
}

const socket = io(ioUrl, {
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 2000,
  reconnectionAttempts: 3,
  // transports: ["polling"],
});
// const manager = new Manager(ioUrl);
// const socket = manager.socket("/api");

// import "highlight.js/styles/arta.min.css";
// import hljs from "highlight.js/lib/core";
// import json from "highlight.js/lib/languages/json";
// import "highlight.js/lib/common";
// import hljsVuePlugin from "@highlightjs/vue-plugin";

// hljs.registerLanguage("json", json);

const app = createApp(App);

app.config.globalProperties.$socket = socket;

app.use(Antd);
// app.use(PerfectScrollbar);
// app.use(hljsVuePlugin);
app.mount("#app");
