<template>
  <a-config-provider
    :theme="{
      token: {
        fontFamily:
          'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospacePingFang SC,Hiragino Sans GB,Microsoft YaHei',
      },
    }"
  >
    <a-layout has-sider style="background: #e8e8e8">
      <a-layout-sider
        :style="{
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: '#000',
        }"
        width="200"
        theme="light"
        class="menu"
      >
        <div
          :style="{
            position: 'fixed',
            top: '0',
            height: '180px',
            width: '200px',
            zIndex: 1,
            background: '#000',
            textAlign: 'center',
            background: 'url(' + require('@/assets/logo.png') + ')',
            backgroundSize: 'contain',
          }"
          class="new_chat"
        >
          <a-button
            :type="show_new_chat ? 'primary' : 'default'"
            style="margin-top: 121px"
            @click="handle_new_chat()"
          >
            <FormOutlined /> NEW CHAT
          </a-button>
          <!-- <p style="color: #fff; font-size: 12px; margin-top: 8px">...</p> -->
        </div>
        <div
          :style="{ marginTop: '180px', overflow: 'auto', height: '100vh' }"
          class="menu"
        >
          <a-menu
            :style="{
              background: '#000',
            }"
            v-model:selectedKeys="menu_selected_keys"
            mode="inline"
            theme="dark"
          >
            <template v-for="item in chat_history_data" :key="item.id">
              <a-menu-item
                @click="handle_click_history(item.id, item.text)"
                @mouseover="item.show_delete = true"
                @mouseleave="item.show_delete = false"
              >
                <DeleteFilled
                  v-if="item.show_delete == true"
                  @click.stop="
                    delete_history_id = item.id;
                    delete_history_text = item.text;
                  "
                  class="delete-history"
                />

                <span class="nav-text">{{ item.text }}</span>
              </a-menu-item>
            </template>
          </a-menu>
          <a-modal
            v-model:open="delete_history_id"
            title="Delete Chat History?"
            @ok="handle_delete_histroy"
            @cancel="handle_cancel_delete_histoy"
            :maskStyle="{ background: 'rgba(255, 255, 255, 0.7)' }"
            :centered="true"
            :okButtonProps="{ danger: true }"
            okText="Delete"
          >
            <p>{{ delete_history_text }}</p>
          </a-modal>
        </div>
      </a-layout-sider>

      <a-layout
        :style="{
          marginLeft: '200px',
          marginRight: '300px',
          background: '#e8e8e8',
          height: '100vh',
        }"
        id="center_layout"
        class="dynamic-width"
      >
        <!-- <a-layout-header :style="{ background: '#fff', padding: 0 }" /> -->
        <div class="content">
          <a-layout-content id="content" class="content content_list">
            <div style="height: 100vh" v-if="chat_loading">
              <a-card
                style="margin: 24px; padding: 24px"
                :bordered="false"
                :bodyStyle="{ padding: 0 }"
              >
                <a-skeleton active :paragraph="{ rows: 5 }"></a-skeleton>
              </a-card>
            </div>
            <div v-if="!chat_loading">
              <a-card
                style="margin: 24px 24px 0 24px; padding: 0"
                :bordered="false"
                :bodyStyle="{ padding: 0 }"
                v-if="show_settings"
              >
                <a-list class="config">
                  <a-list-item>
                    <a-list-item-meta
                      style="
                        margin-top: 10px;
                        margin-bottom: 10px;
                        font-weight: bold;
                      "
                    >
                      <template #description
                        ><span style="color: maroon; font-size: 16px"
                          >SETTINGS</span
                        >
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item>
                    <a-list-item-meta style="margin-top: 10px">
                      <template #description>
                        <a-descriptions title="API KEY" bordered>
                          <a-descriptions-item
                            label="OpenAI API Key"
                            span="3"
                            :labelStyle="{ width: '200px' }"
                          >
                            <a-input
                              v-model:value="config.api.openai_api_key"
                            />
                          </a-descriptions-item>
                          <a-descriptions-item span="3">
                            <template #label
                              >OpenAI API Base
                              <div style="color: #999">(OPTIONAL)</div>
                            </template>
                            <a-input v-model:value="config.api.openai_api_base"
                          /></a-descriptions-item>
                          <a-descriptions-item label="Serper API Key" span="3">
                            <a-input v-model:value="config.api.serper_api_key"
                          /></a-descriptions-item>
                        </a-descriptions>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item>
                    <a-list-item-meta style="margin-top: 10px">
                      <template #description>
                        <a-descriptions title="GENERAL" size="small" bordered>
                          <a-descriptions-item label="LLM Timeout">
                            <a-input-number
                              v-model:value="config.general.llm_timeout"
                              addon-after="s"
                            />
                          </a-descriptions-item>
                          <a-descriptions-item label="Search Timeout"
                            ><a-input-number
                              v-model:value="config.general.search_timeout"
                              addon-after="s"
                          /></a-descriptions-item>
                          <a-descriptions-item label="Browse Timeout"
                            ><a-input-number
                              v-model:value="config.general.browse_timeout"
                              addon-after="s"
                          /></a-descriptions-item>
                          <a-descriptions-item label="LLM Invoke Limit"
                            ><a-input-number
                              v-model:value="config.general.llm_invoke_limit"
                          /></a-descriptions-item>
                          <a-descriptions-item label="Token Count Limit"
                            ><a-input-number
                              v-model:value="config.general.token_count_limit"
                          /></a-descriptions-item>
                          <a-descriptions-item label="Running Timeout"
                            ><a-input-number
                              v-model:value="config.general.running_timeout"
                              addon-after="s"
                          /></a-descriptions-item>
                        </a-descriptions>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item>
                    <a-list-item-meta>
                      <template #description>
                        <a-button
                          type="primary"
                          @click="handle_save_config()"
                          style="margin: 10px 0"
                        >
                          <SaveOutlined /> SAVE SETTINGS
                        </a-button>

                        <a-button
                          type="primary"
                          ghost
                          @click="handle_close_config()"
                          style="margin: 10px 0 10px 15px"
                        >
                          <CloseOutlined /> CLOSE
                        </a-button>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </a-card>
              <a-card
                style="margin: 24px 24px 0 24px; padding: 0"
                :bordered="false"
                :bodyStyle="{ padding: 0 }"
                v-else-if="show_new_chat"
              >
                <a-list>
                  <a-list-item>
                    <a-list-item-meta
                      style="
                        margin-top: 10px;
                        margin-bottom: 10px;
                        font-weight: bold;
                        text-align: center;
                        padding: 50px 0;
                      "
                    >
                      <template #description>
                        <img
                          alt="logo"
                          :src="require('@/assets/logo.png')"
                          class="logo"
                          width="80px"
                          style="border-radius: 50%; border: 3px solid #eee"
                        />
                        <p
                          style="
                            font-size: 20px;
                            color: maroon;
                            margin-top: 15px;
                          "
                        >
                          "Write a question to start a new chat."
                        </p>
                        <br />
                        <p
                          v-for="(item, key) in default_questions"
                          :key="key"
                          @click="handle_default_question(key)"
                        >
                          <a style="" class="default_questions">{{ item }}</a>
                        </p>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </a-card>
              <a-card
                style="margin: 24px 24px 0 24px; padding: 0"
                :bordered="false"
                :bodyStyle="{ padding: 0 }"
                v-else
                v-for="(item, index) in chat_data"
                :key="index"
              >
                <a-list class="chat_list" item-layout="horizontal">
                  <a-list-item class="content_list_item">
                    <a-list-item-meta>
                      <template #title>
                        <UserAddOutlined />
                        <a style="font-weight: bold">&nbsp;Me</a>
                      </template>
                      <template #description>
                        <span
                          style="color: maroon; margin-bottom: 10px"
                          class="me"
                          >{{ item.human.text }}</span
                        >
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item class="content_list_item">
                    <a-list-item-meta>
                      <template #title>
                        <RobotOutlined />
                        <a style="font-weight: bold">&nbsp;SuperSEARCH</a>
                      </template>
                      <template #description>
                        <a-card class="detail" :bodyStyle="{ padding: '15px' }">
                          <span
                            style="
                              color: white;
                              font-size: 14px;
                              margin-bottom: 10px;
                            "
                            v-html="
                              (item.system.text ? item.system.text : '') +
                              (item.system.extra ? item.system.extra : '')
                            "
                          ></span>
                          <span v-if="item.system.doing" class="blinking-icon"
                            >┃</span
                          >
                        </a-card>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item
                    style="
                      background-color: ghostwhite;
                      border-bottom-left-radius: 10px;
                      border-bottom-right-radius: 10px;
                    "
                    class="content_list_item"
                    v-if="!item.system.doing"
                  >
                    <a-list-item-meta>
                      <template #title>
                        <RobotOutlined v-if="item.name == 'SuperSEARCH'" />
                        <RobotOutlined v-if="item.name == 'SYSTEM'" />
                        <UserAddOutlined v-if="item.name == 'Me'" />
                        <OrderedListOutlined
                          v-if="item.name == 'Recommendation'"
                        />

                        <a style="font-weight: bold" v-if="item.name"
                          >&nbsp;{{ item.name }}</a
                        >
                      </template>
                      <template #description>
                        <a-collapse ghost v-if="item.context">
                          <a-collapse-panel key="1" header="Context">
                            <a-list
                              class="detail"
                              bordered
                              :data-source="item.context"
                            >
                              <template #renderItem="{ item }">
                                <a-list-item
                                  style="
                                    color: white;
                                    border-block-end-color: #666;
                                    font-size: 12px;
                                  "
                                  >[Q]:{{ item["question"] }}<br />[A]:{{
                                    item["answer"]
                                  }}</a-list-item
                                >
                              </template>
                            </a-list>
                          </a-collapse-panel>
                        </a-collapse>
                        <span
                          style="color: maroon; margin-bottom: 10px"
                          :class="item.name == 'Me' ? 'me' : ''"
                          v-html="
                            (item.text ? item.text : '') +
                            (item.extra ? item.extra : '')
                          "
                        ></span>
                        <span
                          v-if="text_cursor && item.name == 'SuperSEARCH'"
                          class="blinking-icon-blue"
                          >┃</span
                        >

                        <!-- <a-timeline
                          bordered
                          size="small"
                          v-if="item.timeline && item.timeline.length"
                        >
                          <a-timeline-item
                            v-for="timeline in item.timeline"
                            :key="timeline.step"
                          >
                            <a @click="handle_open_detail(timeline.step)">{{
                              timeline.name
                            }}</a>
                          </a-timeline-item>
                        </a-timeline> -->
                        <p
                          v-if="item.timeline && item.timeline.length > 0"
                          style="margin-bottom: 22px; font-weight: bold"
                        >
                          AGENT RUNNING PROCESS
                        </p>

                        <a-timeline v-if="item.timeline">
                          <a-timeline-item
                            v-for="timeline in item.timeline"
                            :key="timeline.step"
                          >
                            <template #dot>
                              <LoadingOutlined
                                v-if="timeline.doing"
                              /><CheckOutlined v-else />
                            </template>
                            <p
                              :id="
                                'step' + timeline.step + '-loop' + timeline.loop
                              "
                              style="font-weight: bold"
                            >
                              <a
                                @click="handle_open_detail(timeline)"
                                style="font-weight: normal"
                                >{{ timeline.name }}
                              </a>
                            </p>
                          </a-timeline-item>
                        </a-timeline>
                        <a-button
                          class="recommendation"
                          v-for="(button, i) in item.button"
                          :key="i"
                          :type="button.type"
                          @click="
                            handle_send_recommendation(
                              button.action,
                              button.command,
                              button.params
                            )
                          "
                        >
                          <strong v-if="button.action != BR"
                            >[{{ button.action }}]&nbsp;</strong
                          >
                          {{ button.text }}
                        </a-button>
                        <div v-if="item.text" style="height: 10px"></div>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </a-card>
            </div>
          </a-layout-content>
        </div>

        <a-layout
          :style="{
            position: 'fixed',
            bottom: '0',
            left: '200px',
            right: '300px',
            textAlign: 'center',
            background: '#e8e8e8',
            padding: '24px',
          }"
          id="input_layout"
          class="dynamic-width"
        >
          <a-input-group compact size="large">
            <a-textarea
              ref="input_textarea"
              v-model:value="input_box.text"
              :auto-size="{ minRows: 1, maxRows: 5 }"
              placeholder="按 Ctrl + Enter 发送信息"
              style="
                width: calc(100% - 48px);
                text-align: left;
                border-color: #ccc;
              "
            />
            <a-button
              type="primary"
              @click="handle_send_message()"
              :danger="input_box.danger"
            >
              <ArrowUpOutlined v-if="input_box.send_icon" />
              <CloseSquareOutlined v-if="input_box.stop_icon" />
              <LoadingOutlined v-if="input_box.loading" />
            </a-button>
          </a-input-group>
        </a-layout>
      </a-layout>
      <a-layout
        :style="{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          background: '#e8e8e8',
          width: '300px',
        }"
        theme="light"
        id="right_layout"
        class="dynamic-width"
      >
        <a-card
          style="margin: 24px; padding: 0"
          :bordered="false"
          :bodyStyle="{ padding: 0 }"
          :headStyle="{ borderBottom: '2px solid #333' }"
          v-show="!right_setting_show"
        >
          <template #extra></template>
          <a-list
            class="running_list"
            item-layout="horizontal"
            :data-source="running_data"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta>
                  <template #description>
                    <!-- <a
                      @click="
                        scroll_to('step' + step.index + '-loop' + step.loop)
                      "
                      v-for="step in item.step"
                      :key="step.index"
                      :class="'step ' + step.status"
                      >{{ step.index }}</a
                    >
                    <span
                      v-if="item.work"
                      style="display: block; color: maroon"
                      >{{ item.work }}</span
                    > -->
                    <a-collapse v-model:activeKey="activeKey" ghost>
                      <a-collapse-panel
                        key="0"
                        v-if="item.tool"
                        :headStyle="{ borderBottom: '1px solid #ccc' }"
                      >
                        <template #header>
                          <span style="color: brown; font-weight: bold">
                            <CodeOutlined /> TOOL
                          </span>
                        </template>
                        <a-card class="detail" :bodyStyle="{ padding: '15px' }">
                          <span v-html="item.tool"></span>
                        </a-card>
                      </a-collapse-panel>
                      <!-- <a-collapse-panel
                        key="1"
                        v-if="item.prompt"
                        :style="{ color: 'blue' }"
                        :headStyle="{ borderBottom: '1px solid #ccc' }"
                      >
                        <template #header>
                          <span style="color: purple; font-weight: bold">
                            <FileTextOutlined /> PROMPT
                          </span>
                        </template>
                        <a-card class="detail" :bodyStyle="{ padding: '15px' }">
                          {{ item.prompt }}
                        </a-card>
                      </a-collapse-panel> -->
                      <a-collapse-panel key="2" v-if="item.response">
                        <template #header>
                          <span style="color: darkcyan; font-weight: bold">
                            <UnorderedListOutlined /> RESPONSE
                          </span>
                        </template>
                        <a-card class="detail" :bodyStyle="{ padding: '15px' }">
                          <span v-html="item.response"></span>
                          <span v-if="response_cursor" class="blinking-icon"
                            >┃</span
                          >
                        </a-card>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" v-if="item.result">
                        <template #header>
                          <span style="color: crimson; font-weight: bold">
                            <DownSquareOutlined /> NEXT
                          </span>
                        </template>
                        <a-card class="detail" :bodyStyle="{ padding: '15px' }">
                          <span v-html="item.result"></span>
                        </a-card>
                      </a-collapse-panel>
                    </a-collapse>
                  </template>
                  <template #title>
                    {{ item.name }} &nbsp;<LoadingOutlined
                      v-if="item.doing"
                      style="color: royalblue"
                    />
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </a-card>
        <a-card
          style="margin: 24px; padding: 0"
          :bordered="false"
          :bodyStyle="{ padding: 0 }"
          v-show="right_setting_show"
        >
          <template #extra></template>
          <a-list item-layout="horizontal">
            <a-list-item>
              <a-list-item-meta>
                <template #title><strong>LLM in use</strong></template>
                ><template #description
                  ><a-radio-group v-model:value="llm" @change="change_llm()">
                    <a-radio value="gpt3.5" class="radio">GPT3.5</a-radio>
                    <a-radio value="mistral" class="radio">Mistral</a-radio>
                    <a-radio value="user" class="radio">User</a-radio>
                    <a-radio value="llama2" class="radio">Llama2</a-radio
                    ><a-radio value="codellama" class="radio"
                      >codellama</a-radio
                    >
                    <a-radio value="llama2:13b" class="radio"
                      >Llama2(13B)</a-radio
                    >
                    <a-radio value="gemma:7b" class="radio">Gemma(7B)</a-radio>
                    <!-- <a-radio value="codellama" class="radio">CodeLlama</a-radio> -->
                  </a-radio-group>
                </template>
              </a-list-item-meta>
            </a-list-item>
            <a-list-item>
              <a-list-item-meta>
                <template #title><strong>Agent details</strong></template>
                ><template #description
                  ><a-switch
                    v-model:checked="details"
                    @change="handle_details_changed()"
                  />
                </template>
              </a-list-item-meta>
            </a-list-item>
            <a-list-item v-if="is_local">
              <a-list-item-meta>
                <template #description>
                  <a-button
                    :ghost="!show_settings"
                    type="primary"
                    @click="handle_click_settings()"
                  >
                    <SettingOutlined /> SETTINGS
                  </a-button>
                </template>
              </a-list-item-meta>
            </a-list-item>
            <a-list-item v-if="is_local">
              <a-list-item-meta>
                <template #description>
                  <a-button
                    :ghost="!show_settings"
                    type="primary"
                    @click="handle_test()"
                  >
                    <SettingOutlined /> TEST
                  </a-button>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </a-card>
      </a-layout>
    </a-layout>
    <a-drawer
      v-model:open="detail_open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red; white-space: pre-wrap"
      title="Basic Drawer"
      placement="right"
      @after-open-change="afterOpenChange"
      size="large"
    >
      <a-collapse v-model:activeKey="activeKey" ghost>
        <a-collapse-panel
          key="0"
          v-if="detail_data.tool"
          :headStyle="{ borderBottom: '1px solid #ccc' }"
        >
          <template #header>
            <span style="color: brown; font-weight: bold">
              <CodeOutlined /> TOOL
            </span>
          </template>
          <a-card class="detail" :bodyStyle="{ padding: '15px' }">
            <span v-html="detail_data.tool"></span>
          </a-card>
        </a-collapse-panel>
        <a-collapse-panel
          key="1"
          v-if="detail_data.prompt"
          :style="{ color: 'blue' }"
          :headStyle="{ borderBottom: '1px solid #ccc' }"
        >
          <template #header>
            <span style="color: purple; font-weight: bold">
              <FileTextOutlined /> PROMPT
            </span>
          </template>
          <a-card class="detail" :bodyStyle="{ padding: '15px' }">
            {{ detail_data.prompt }}
          </a-card>
        </a-collapse-panel>
        <a-collapse-panel key="2" v-if="detail_data.response">
          <template #header>
            <span style="color: darkcyan; font-weight: bold">
              <UnorderedListOutlined /> RESPONSE
            </span>
          </template>
          <a-card class="detail" :bodyStyle="{ padding: '15px' }">
            <span v-html="detail_data.response"></span>
            <span v-if="response_cursor" class="blinking-icon">┃</span>
          </a-card>
        </a-collapse-panel>
        <a-collapse-panel key="3" v-if="detail_data.result">
          <template #header>
            <span style="color: crimson; font-weight: bold">
              <DownSquareOutlined /> NEXT
            </span>
          </template>
          <a-card class="detail" :bodyStyle="{ padding: '15px' }">
            <span v-html="detail_data.result"></span>
          </a-card>
        </a-collapse-panel>
      </a-collapse>
    </a-drawer>
  </a-config-provider>
</template>
<script setup>
import {
  onMounted,
  onUnmounted,
  ref,
  h,
  getCurrentInstance,
  nextTick,
} from "vue";

const currentInstance = getCurrentInstance();
const { $socket } = currentInstance.appContext.config.globalProperties;

import { Modal, message } from "ant-design-vue";

import {
  ArrowUpOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseSquareOutlined,
  FormOutlined,
  FileTextOutlined,
  CodeOutlined,
  UnorderedListOutlined,
  DownSquareOutlined,
  DeleteFilled,
  RobotOutlined,
  UserAddOutlined,
  OrderedListOutlined,
  SettingOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";

import Cookies from "js-cookie";

// 变量
const is_local = process.env.NODE_ENV == "development";

const detail_open = ref(false);
const detail_data = ref({});
const running_data = ref([]);

const right_setting_show = ref(true);

const socket_id = ref("");
const client_id = ref("");

const show_settings = ref(false);
const show_new_chat = ref(false);

const delete_history_id = ref(0);
const delete_history_text = ref("");

const menu_selected_keys = ref([1]);
const chat_history_data = ref([]);

const input_textarea = ref();

const office_data = ref([
  {
    agent: "Supervisor",
    avatar: "a4.png",
    step: [],
  },
  {
    agent: "Search",
    avatar: "a2.png",
    step: [],
  },
  {
    agent: "Browser",
    avatar: "a3.png",
    step: [],
  },
  {
    agent: "Filter",
    avatar: "a6.png",
    step: [],
  },
]);

const llm = ref("");
const details = ref(true);

const current_chat_id = ref(0);
const current_chat_loop = ref(0);

const chat_data = ref([]);
const activeKey = ref(["0", "2", "3"]);

const response_cursor = ref(false);
const text_cursor = ref(false);

const chat_loading = ref(false);

const config = ref(false);

const input_box = ref({
  text: "",
  send_icon: true,
  stop_icon: false,
  danger: false,
  loading: false,
});

const default_questions = ref([
  "中国哪些城市有高达基地",
  "Who are the top 5 points per game leaders in the current NBA season. What were their draft positions?",
  "What major world news events happened yesterday.Select one of the news to search for and analyze it in depth.",
]);

// 基础方法

const $id = (id) => {
  return document.getElementById(id);
};

const func_highlight = (text) => {
  text = text.replace(/(\w+)\(/g, "<span class='func'>$1</span>(");
  text = text.replace(/(".+")/g, "<span class='str'>$1</span>");
  text = url_highlight(text);
  return text;
};

const json_highlight = (text) => {
  text = text.replace(/("\w+?"):/g, "<span class='key2'>$1</span>:");
  text = text.replace(
    /([^>\w])(".+?")([^<])/g,
    "$1<span class='str'>$2</span>$3"
  );
  return text;
};

const url_highlight = (text) => {
  text = text.replace(
    /((https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])/g,
    "<a href='$1' class='url' target='_blank'>$1</a>"
  );
  return text;
};

const n_2_br = (text) => {
  text = text.replace(/\\n/g, "<br />");
  text = text.replace(/\\"/g, '"');
  return text.replace('",', "");
};

const on_refresh = () => {
  chat_data_loading();
  send_action("init");
  send_action("load_config");

  input_textarea.value.focus();

  Modal.warning({
    title: "Initializing...",
    icon: h(LoadingOutlined, {
      style: "font-size: 24px; color: #00b96b",
    }),
    centered: true,
    footer: null,
  });
};

const send_action = function (action, more = {}) {
  const data = Object.assign(
    {
      action: action,
      client_id: client_id.value,
    },
    more
  );

  $socket.emit("action", data);
};

const scroll_to_bottom = () => {
  setTimeout(() => {
    let content = document.querySelector(".content_list");
    content.scrollTop = content.scrollHeight;
    // content.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end",
    // });
  }, 500);
};

const scroll_to_bottom_right = () => {
  setTimeout(() => {
    let content = $id("right_layout");
    // console.log(content);
    content.scrollTop = content.scrollHeight;
    // console.log(content.scrollHeight, content.scrollTop);
    // content.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end",
    // });
  }, 500);
};

const scroll_to_top = () => {
  let element = document.querySelector(".content_list");
  element.scrollIntoView({
    behavior: "smooth",
  });
};

// const scroll_to = (id) => {
//   let element = document.getElementById(id);
//   element.scrollIntoView({
//     behavior: "smooth",
//   });
// };

let close_modal_timer;

const close_modal = () => {
  clearTimeout(close_modal_timer);
  close_modal_timer = setTimeout(() => {
    Modal.destroyAll();
    chat_data_loaded();
  }, 200);
};

const on_action = () => {
  $socket.on("action", (json) => {
    console.log("action: ", $socket.id, json);

    if (json.action == "save_client_id") {
      client_id.value = json.client_id;
      Cookies.set("client_id", json.client_id, { expires: 365 });
      console.log("client_id: ", client_id.value);

      on_refresh();
    }

    if (json.action == "config_saved") {
      message.success("Config Saved.");
      setTimeout(() => {
        show_new_chat.value = true;
        show_settings.value = false;
      }, 2000);
    }

    if (json.action == "llm_changed") {
      message.success("LLM Changed.");
    }

    if (json.action == "llm_error") {
      message.error(json.error);
      llm.value = json.server_llm;
    }

    if (json.action == "update_office") {
      let one;

      for (const v of office_data.value) {
        if (v.agent.toLowerCase() == json.agent.toLowerCase()) {
          one = v;
          break;
        }
      }

      if (one) {
        one.doing = json.doing;
        one.work = json.work;
        if (json.add_step) {
          one.step.push(json.add_step);
        }
      }
    }

    if (json.action == "config_loaded") {
      config.value = json.config;
    }

    if (json.action == "sync_llm") {
      llm.value = json.llm;
    }

    if (json.action == "update_history") {
      if (json.history && json.history.length > 0) {
        chat_history_data.value = json.history;
        menu_selected_keys.value = [json.history[0].id];
        current_chat_id.value = json.history[0].id;
        show_new_chat.value = false;
      } else {
        show_new_chat.value = true;
        close_modal();
      }
    }

    if (json.action == "refresh_history") {
      if (json.history) {
        chat_history_data.value = json.history;

        let selected_history_is_not_exist = true;
        for (const v of chat_history_data.value) {
          if (v.id == menu_selected_keys.value[0]) {
            selected_history_is_not_exist = false;
            break;
          }
        }

        if (selected_history_is_not_exist) {
          chat_data.value = [];
          handle_new_chat();
        }
      }
    }

    if (json.action == "load_chat_data") {
      for (const k in json.data) {
        if (json.data[k].timeline) {
          for (const v in json.data[k].timeline) {
            if (json.data[k].timeline[v].tool) {
              json.data[k].timeline[v].tool = func_highlight(
                json.data[k].timeline[v].tool
              );
            }
            if (json.data[k].timeline[v].response) {
              json.data[k].timeline[v].response = url_highlight(
                json_highlight(json.data[k].timeline[v].response)
              );
            }
          }
        }
      }

      chat_data.value = json.data;

      update_chat_loop();
      scroll_to_bottom();
      scroll_to_bottom_right();
      close_modal();
    }

    if (json.action == "no_history") {
      show_new_chat.value = true;
      close_modal();
    }

    if (json.action == "Q") {
      // const button = [];
      // for (const v in json.params.question) {
      //   button.push({
      //     action: "Q",
      //     text: json.params.question[v],
      //     params: {
      //       text: json.params.question[v],
      //     },
      //     type: "default",
      //   });
      // }
      // chat_data.value.push({
      //   name: "Recommendation",
      //   button: button,
      // });
      // scroll_to_bottom();
    }

    if (json.action == "CMD") {
      // if (json.command == "regenerate") {
      //   chat_data.value.push({
      //     name: "Recommendation",
      //     button: [
      //       {
      //         action: "CMD",
      //         text: "Regenerate the chat.",
      //         command: "regenerate",
      //         params: json.params,
      //         type: json.params.status == "abort" ? "primary" : "default",
      //       },
      //     ],
      //   });
      // }
    }
  });
};

// 左侧

const handle_delete_histroy = () => {
  send_action("delete_history", {
    id: delete_history_id.value,
  });

  handle_cancel_delete_histoy();
};

const handle_cancel_delete_histoy = () => {
  delete_history_id.value = 0;
  delete_history_text.value = "";
};

const handle_new_chat = () => {
  menu_selected_keys.value = [0];
  current_chat_id.value = 0;
  current_chat_loop.value = 0;

  chat_data.value = [];
  input_textarea.value.focus();

  show_new_chat.value = true;
  show_settings.value = false;
  scroll_to_top();

  console.log(input_textarea.value);
};

const handle_click_history = function (id, text) {
  show_new_chat.value = false;
  show_settings.value = false;

  menu_selected_keys.value = [id];
  current_chat_id.value = id;

  chat_data_loading();

  Modal.warning({
    title: "Loading Chat Message...",
    icon: h(LoadingOutlined, {
      style: "font-size: 24px; color: #00b96b",
    }),
    content: text,
    centered: true,
    footer: null,
  });

  send_action("get_chat_message", {
    id: id,
  });
};

// 中间

const handle_open_detail = (timeline) => {
  detail_data.value = timeline;
  detail_open.value = true;
};

const handle_default_question = (key) => {
  const text = default_questions.value[key];
  show_new_chat.value = false;

  chat_data.value = [];

  send_action("human_text", {
    text: text,
    new_chat: true,
    chat_id: current_chat_id.value,
    chat_loop: current_chat_loop.value,
  });

  hide_send_button();
};

const handle_save_config = () => {
  send_action("save_config", {
    config: config.value,
  });
};

const handle_close_config = () => {
  show_new_chat.value = true;
  show_settings.value = false;
};

const chat_data_loading = () => {
  chat_loading.value = true;
  scroll_to_top();
};

const chat_data_loaded = () => {
  chat_loading.value = false;
  scroll_to_bottom();
};

const update_chat_loop = () => {
  let loop = 0;
  for (const v of chat_data.value) {
    if (v.name == "Me") {
      loop += 1;
    }
  }
  current_chat_loop.value = loop;
};

const hide_send_button = () => {
  input_box.value.send_icon = false;
  input_box.value.stop_icon = true;
  input_box.value.danger = true;
  input_box.value.loading = false;

  for (const k in office_data.value) {
    office_data.value[k].step = [];
  }
};

const display_send_button = () => {
  input_box.value.send_icon = true;
  input_box.value.stop_icon = false;
  input_box.value.danger = false;
  input_box.value.loading = false;
};

const handle_send_message = () => {
  if (input_box.value.loading) {
    return;
  }

  if (input_box.value.stop_icon) {
    send_action("stop_chat");

    input_box.value.stop_icon = false;
    input_box.value.loading = true;
  }

  if (!input_box.value.text) {
    return;
  }

  send_action("human_text", {
    text: input_box.value.text,
    new_chat: show_new_chat.value,
    chat_id: current_chat_id.value,
    chat_loop: current_chat_loop.value,
  });

  input_box.value.text = "";

  hide_send_button();
};

const handle_send_recommendation = (action, command, params) => {
  if (action == "CMD" && command == "regenerate") {
    send_action(action, {
      command: command,
      params: {
        chat_id: current_chat_id.value,
        chat_loop: current_chat_loop.value,
      },
    });
  }

  if (action == "Q") {
    send_action("human_text", {
      text: params.text,
      new_chat: false,
      chat_id: current_chat_id.value,
      chat_loop: current_chat_loop.value,
    });
  }

  hide_send_button();
};

// ctrl + enter提交send
document.onkeydown = (e) => {
  if (e.ctrlKey && e.keyCode == 13) {
    handle_send_message();

    return false;
  }
};

const on_message = () => {
  $socket.on("message", (json) => {
    console.log("ws: ", json);

    if (json.action == "human_text") {
      // chat_data.value.push({
      //   name: "Me",
      //   text: json.text,
      //   context: "",
      //   timeline: [],
      //   extra: "",
      // });

      chat_data.value.push({
        human: {
          name: "Me",
          text: json.text,
        },
        system: {
          name: "SuperSEARCH",
          text: "",
          doing: true,
          extra: "",
        },
        timeline: [],
      });
    }

    if (json.action == "new_chat") {
      // chat_data.value.push({
      //   name: "SuperSEARCH",
      //   text: "",
      //   context: "",
      //   timeline: [],
      //   extra: "",
      // });
      text_cursor.value = true;
      // chat_data.value.push({
      //   name: "",
      //   text: "",
      //   context: "",
      //   timeline: [],
      //   extra: "",
      // });
    }

    if (json.action == "manual_stop") {
      // chat_data.value.push({
      //   name: "SYSTEM",
      //   text: json.text,
      //   context: "",
      //   timeline: [],
      //   extra: "",
      // });
    }

    if (json.action == "limit_reached") {
      // chat_data.value.push({
      //   name: "SYSTEM",
      //   text: json.text,
      //   context: "",
      //   timeline: [],
      //   extra: "",
      // });
    }

    if (json.action == "agent_run") {
      const last = chat_data.value.length - 1;

      chat_data.value[last].timeline.push({
        name: json.name,
        tool: "",
        prompt: "",
        response: "",
        result: "",
        doing: true,
        step: json.step,
        loop: json.loop,
      });

      running_data.value = chat_data.value[last].timeline;

      right_setting_show.value = false;
      $id("center_layout").style.marginRight = "500px";
      $id("input_layout").style.right = "500px";
      $id("right_layout").style.width = "500px";
    }

    if (["prompt", "result"].includes(json.action)) {
      const last = chat_data.value.length - 1;
      const last_timeline = chat_data.value[last].timeline.length - 1;

      chat_data.value[last].timeline[last_timeline][json.action] = json.text;

      response_cursor.value = false;
    }

    if (json.action == "tool") {
      const last = chat_data.value.length - 1;
      const last_timeline = chat_data.value[last].timeline.length - 1;

      chat_data.value[last].timeline[last_timeline].tool = func_highlight(
        json.text
      );
    }

    if (json.action == "agent_done") {
      const last = chat_data.value.length - 1;
      const last_timeline = chat_data.value[last].timeline.length - 1;

      chat_data.value[last].timeline[last_timeline].doing = false;
    }

    if (json.action == "response_stream") {
      const last = chat_data.value.length - 1;
      const last_timeline = chat_data.value[last].timeline.length - 1;
      let text;

      if (json.text == "[END]") {
        text = url_highlight(
          chat_data.value[last].timeline[last_timeline].response
        );
      } else {
        text = json_highlight(
          chat_data.value[last].timeline[last_timeline].response + json.text
        );

        response_cursor.value = true;
      }

      chat_data.value[last].timeline[last_timeline].response = text;
    }

    if (json.action == "system_text") {
      // chat_data.value.push({
      //   name: "SuperSEARCH",
      //   text: "",
      //   context: json.context,
      //   timeline: [],
      //   extra: "",
      // });
    }

    if (json.action == "system_stream") {
      const last = chat_data.value.length - 1;

      if (json.text != "[END]") {
        let text = chat_data.value[last].system.text;
        text = n_2_br(text + json.text);
        chat_data.value[last].system.text = text;

        text_cursor.value = true;
      } else {
        chat_data.value[last].system.doing = false;
      }
    }

    if (json.action == "extra") {
      const last = chat_data.value.length - 1;
      chat_data.value[last].system.extra = json.extra;

      text_cursor.value = false;
      chat_data.value[last].system.doing = false;
      display_send_button();

      right_setting_show.value = true;
      $id("center_layout").style.marginRight = "300px";
      $id("input_layout").style.right = "300px";
      $id("right_layout").style.width = "300px";
    }

    update_chat_loop();
    scroll_to_bottom();
    scroll_to_bottom_right();
  });
};

// const has_timeline = (item) => {
//   if (item.timeline && item.timeline.length > 0) {
//     return {
//       backgroundColor: "ghostwhite",
//     };
//   }
// };

//右侧

const change_llm = () => {
  send_action("change_llm", {
    llm: llm.value,
  });
};

const handle_details_changed = () => {
  for (const node of document.getElementsByClassName("chat_detail")) {
    node.style.display = details.value ? "block" : "none";
  }
};

const handle_click_settings = () => {
  show_settings.value = true;
  show_new_chat.value = false;

  menu_selected_keys.value = [0];

  scroll_to_top();
};

const handle_test = () => {
  $id("center_layout").style.marginRight = "500px";
  $id("input_layout").style.right = "500px";
  $id("right_layout").style.width = "500px";
};

// 全局事件

onMounted(() => {
  document.body.style.background = "#e8e8e8";
  document.title = "SuperSEARCH";

  on_message();
  on_action();

  $socket.on("connect", () => {
    socket_id.value = $socket.id;

    if (!Cookies.get("client_id")) {
      send_action("new_client_id");
    } else {
      client_id.value = Cookies.get("client_id");
      console.log(client_id.value);

      on_refresh();
    }
  });
});

onUnmounted(() => {
  $socket.off("message");
  $socket.off("action");
});

nextTick(() => {
  // scroll_to_bottom();
});
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}

.blinking-icon {
  animation: blink 0.8s infinite; /* 1s表示动画时长，infinite表示无限循环 */
  color: white;
}

.blinking-icon-blue {
  animation: blink 0.8s infinite; /* 1s表示动画时长，infinite表示无限循环 */
  color: blue;
}

.content {
  overflow: auto;
  white-space: pre-wrap;
  height: calc(100vh - 90px);
}

.detail {
  background: #333;
  color: #fff;
  font-size: 12px;
}

.detail div.ant-card-body {
  padding: 15px;
}

.menu ::-webkit-scrollbar {
  width: 5px;
}

.menu ::-webkit-scrollbar-thumb {
  background: #666;
  /* opacity: 0.5; */
  border-radius: 5px;
  height: 100px;
}

.content ::-webkit-scrollbar {
  width: 10px;
}

.content ::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
  height: 100px;
}

.new_chat .ant-btn-default {
  background: black;
  color: #fff;
  border-color: white;
}

.new_chat .ant-btn-default:hover {
  background: #fff;
  color: #00b96b;
  border-color: #00b96b;
}

.delete-history :hover {
  color: red;
}

.recommendation {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  max-width: 100%;
  overflow: hidden;
  /* text-overflow: clip; */
  /* color: #1677ff; */
}

:deep(.ant-timeline-item-head) {
  background-color: ghostwhite;
}

:deep(.url) {
  color: lightskyblue;
}

:deep(.url:hover) {
  color: rgb(66, 81, 218);
}

:deep(.func) {
  color: gold;
  font-weight: bold;
}

:deep(.str) {
  color: mintcream;
}

:deep(.key) {
  color: #d19a66;
  font-weight: bold;
}

:deep(.key2) {
  color: powderblue;
  font-weight: bold;
}

:deep(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  padding-left: 0;
}

:deep(
    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box
  ) {
  padding-top: 0;
}

.step {
  margin-right: 4px;
  color: white;
  font-size: 12px;
  display: inline-block;

  padding: 0 3px;
  height: 16px;
}

.success:hover,
.fail:hover,
.continue:hover {
  background: dodgerblue;
}

.success {
  background: #00b96b;
}

.fail {
  background: firebrick;
}

.continue {
  background: orange;
}

:deep(.config input) {
  color: maroon;
}

:deep(.ant-input-number) {
  width: 100%;
}

:deep(.ant-input-number-group-addon) {
  background-color: white;
}

.radio {
  color: maroon;
}

.default_questions {
  color: #666;
  font-size: 14px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  width: 500px;
  font-weight: normal;
  text-align: left;
}

.default_questions:hover {
  background: #f0f0f0;
  color: black;
}

:deep(.ant-timeline .ant-timeline-item) {
  padding-bottom: 0;
}

:deep(.ant-timeline .ant-timeline-item-last > .ant-timeline-item-content) {
  min-height: 0;
}

.dynamic-width {
  transition: 0.5s ease-in-out;
}

.me {
  font-size: 18px;
  font-weight: bold;
}

.running_list {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
